import React, { useState, useEffect } from 'react';
import Navbar from './components/NavBar';
import CalendarCard from './components/CalendarCard';
import './App.css'; // For adding your CSS styles

function App() {
  
  const [isVisible, setIsVisible] = useState(false); // Calendar visibility
  const [showIcon, setShowIcon] = useState(true); // Show the scroll icon
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);


  // Function to handle arrow click
  const handleArrowClick = () => {
    setIsCalendarVisible(true);
    window.scrollTo({ top: window.innerHeight, behavior: 'smooth' });
  };


  // Handle the click event to reveal the calendar and hide the icon
  const handleIconClick = () => {
    setIsCalendarVisible(true);
    // Scroll to calendar smoothly
    window.scrollTo({ top: window.innerHeight, behavior: 'smooth' });
  };

  // Monitor scroll event to toggle the icon visibility
  const handleScroll = () => {
    if (window.scrollY === 0) {
      setIsCalendarVisible(false);
    }
    if (window.scrollY > 100) {
      setIsCalendarVisible(true); // Show the calendar
    } else {
      setIsCalendarVisible(false); // Hide the calendar when scrolling back up
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  
  
  return (
    <div className="App">
      <Navbar />
       {/* Scroll Icon */}
       {!isCalendarVisible && (
        
        <div className="scroll-icon" onClick={handleArrowClick}>
          ↓
        </div>
      )}
      <main  style={styles.main}>
        {/* Your interactive calendar or other content can go here */}
        <CalendarCard  isVisible={isCalendarVisible}/>
        {/*<h2>Interactive Calendar Goes Here</h2>*/}
      </main>

      
      
    </div>
  );
}
const styles = {
  main: {
    
    marginTop: '100px', // Adds space under the fixed navbar
    padding: '20px',
    position: 'relative',
    zIndex: '1',
    textAlign: 'center',
  },

  arrowContainer: {
    position: 'absolute',
    top: '90vh',
    left: '50%',
    transform: 'translateX(-50%)',
    fontSize: '30px',
    cursor: 'pointer',
    zIndex: 999,
  },
  arrow: {
    animation: 'bounce 2s infinite', // Optional: you can add a bouncing animation for the arrow
  },
};


export default App;



