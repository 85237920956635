import React from 'react';
import Calendar from './Calendar';

function CalendarCard({ isVisible }) {
  

  return (
    <div id="calendar-card" 
      style={{
        ...styles.card,
        ...(isVisible ? styles.calendarCardShow : {}),
      }}
    >
      <h3 style={styles.cardTitle}>My Calendar</h3>
      <div style={styles.calendar}>
        <Calendar />
      </div>
    </div>
  );
  
}

const styles = {
  card: {
    position: 'absolute',
    top: '120vh', // Initially hidden off the screen
    left: '50%',
    transform: 'translateX(-50%)',
    width: '80%',
    maxWidth: '1200px',
    padding: '40px',
    backgroundColor: '#fff',
    
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    transition: 'opacity 0.5s, top 0.5s',
    opacity: 0, // Initially hidden
    color: '#4B658'
    
    //?zIndex: '10', // To ensure the card appears on top of other elements
  },
  calendarCardShow: {
    top: '20vh', // Moves up when visible
    opacity: 1, // Fully visible
  },

  
  cardTitle: {
    fontSize: '2rem',
    textAlign: 'center',
    marginBottom: '20px',
    color: '#333',
  },
  calendar: {
    fontSize: '1.2rem',
    textAlign: 'center',
    border: "1px solid #C78A66",
  },

  calendarCardShow: {
    top: '20vh', // Moves up when visible
    opacity: 1,  // Fully visible
  },
};

export default CalendarCard;


