import React, { useState } from 'react';

const Calendar = () => {
  const [events, setEvents] = useState({});
  const [selectedDate, setSelectedDate] = useState(null);
  const [newEvent, setNewEvent] = useState('');

  const handleDateClick = (date) => {
    setSelectedDate(date);
  };

  const handleAddEvent = () => {
    if (selectedDate && newEvent) {
      setEvents({
        ...events,
        [selectedDate]: newEvent,
      });
      setNewEvent('');
      setSelectedDate(null);
    }
  };

  const renderDays = () => {
    const daysInMonth = new Date(2024, new Date().getMonth() + 1, 0).getDate();
    const days = [];
    for (let i = 1; i <= daysInMonth; i++) {
      const day = `2024-${new Date().getMonth() + 1}-${i}`;
      days.push(
        <div
          key={i}
          onClick={() => handleDateClick(day)}
          style={{
            border: '1px solid #ccc',
            padding: '10px',
            margin: '5px',
            textAlign: 'center',
            cursor: 'pointer',
          }}
        >
          {i}
          <div>{events[day]}</div>
        </div>
      );
    }
    return days;
  };

  return (
    <div style={styles.calendarContainer}>
      <h3>Interactive Calendar</h3>
      <div style={styles.calendarGrid}>{renderDays()}</div>

      {selectedDate && (
        <div style={styles.eventInput}>
          <h4>Add event for {selectedDate}</h4>
          <input
            type="text"
            placeholder="Enter event (e.g. Cooking Dinner)"
            value={newEvent}
            onChange={(e) => setNewEvent(e.target.value)}
          />
          <button onClick={handleAddEvent}>Add Event</button>
        </div>
      )}
    </div>
  );
};

const styles = {
  calendarContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  calendarGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(7, 1fr)',
    gridGap: '10px',
    margin: '20px',
  },
  eventInput: {
    marginTop: '20px',
    textAlign: 'center',
  },
};

export default Calendar;
