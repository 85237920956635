import React from 'react';
import logo from '../logo.svg';  // Import the logo

import './Navbar.css';  // Import the CSS file



const Navbar = () => {
  return (
    <nav style={styles.nav}>
        <div style={styles.logoContainer}>
            <img src={logo}  alt="React Logo" style={styles.reactIcon}  />
            <h2 style={styles.subTitle2}>Daily Activities Tracker</h2>
        </div>
      <div style={styles.container}>
        <h1 style={styles.title}>tupace.com</h1>
        <h3 style={styles.subTitle}>a project of erstebahn.com</h3>
      </div>
    </nav>
  );
};

const styles = {
  nav: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    zIndex: '1',
   
    justifyContent: 'space-between', // This will push other elements to the right
   
    padding: '10px',
    paddingTop: '80px',
    paddingBottom: '80px',
    backgroundColor: '#4B658E', /* Dark blue from the sky in the image */
    color: 'white',
  },
 
  logoContainer: {
    display: 'flex',
    alignItems: 'center', // Aligns the logo and subtitle vertically in the center
  },
  reactIcon: {
    marginLeft: 20,
    width: '150px', // Increase the width to make it larger
    height: '150px', // Adjust the height as well
    margin: '20px auto', // Center the icon with some margin
    display: 'block', // Ensure it's centered horizontally
    animation: 'spin infinite 1s linear',  // Add animation
  },
 
  container: {
    textAlign: 'center',
    marginRight: '30px'
  },

  subTitle2:{
    marginLeft: '0px',
    textAlign: 'left',
    fontSize: '2rem', // Slightly smaller subtitle
    color: '#F5EFE6',
    zIndex:'100px',
  },

  title: {
    fontSize: '5rem', // Big font size for the main title
    fontWeight: 'bold',
    margin: '0 0 0 10px',
    color: '#F5EFE6', // Customize the color as needed
  },
 
  subTitle: {
    fontSize: '2rem', // Slightly smaller subtitle
    color: '#F5EFE6',
    marginTop: '10px',
  },
};



export default Navbar;


